import React from 'react';

export default function Select (props){
    const categories = ([
        ['9', 'General Knowledge'],
        ['10', 'Entertainment Books'],
        ['11', 'Entertainment Film'],
        ['12', 'Entertainment Music'],
        ['13', 'Entertainment musicals & Theatres'],
        ['14', 'Entertainment Television'],
        ['15', 'Entertainment Vidio Games'],
        ['16', 'Entertainment Board Games'],
        ['17', 'Science & Nature'],
      ]);
      const amounts = ([
          ['5','5'],
          ['10','10'],
          ['15','15'],
          ['20','20']
      ])
      const rows = []
      const elements = props.elements === 'ctegories' ? [...categories] : [...amounts]
      elements.forEach(function (element,index) {
        rows.push(<SelectRow key={index} element={element} />)
    })
    const label = props.elements === 'ctegories' ? 'Select Category: ' : 'Number of Questions:'
    return<div className="form-group">
        <label htmlFor={label}>{label}</label>
    <select value={props.value} onChange={props.onChange}>
    {rows}
    </select>
        </div>
    
}

function SelectRow({ element }) {
    const name = element[1]
    const id = element[0]
    return <option value={id}>{name}</option>
}