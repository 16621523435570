import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
  } from "react-router-dom";
  import Exemple from './Exemple';
  import Quiz from './Quiz';

export default function Navbar(){
    return (
        <React.Fragment>
          <Router><nav className="navbar navbar-dark bg-dark ">
          
          <Link className="btn btn-secondary menu-item" to="/example" >Exemple</Link> 
          <Link className="btn btn-secondary menu-item" to="/quiz" >QUIZ</Link> 
          {/* <a className="btn btn-secondary menu-item" href="https://opentdb.com/api.php?amount=10&type=multiple#">Home</a> */}
            {/* <a className="btn btn-secondary menu-item" href="https://opentdb.com/api.php?amount=10&type=multiple#">Quiz</a> */}
</nav>
            <Switch>
            <Route exact path="/">
            <Exemple />
          </Route>
          <Route exact path="/quiz">
            <Quiz />
          </Route>
          <Route exact path="/example">
            <Exemple />
          </Route>
          
        </Switch>
            </Router>
          
        </React.Fragment>
      );
}