
import React, { useState, useEffect } from 'react';
import getQuestions from './Questions';
//import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Select from './Select';
import CounterDown from './CounterDown';

export default function Quiz() {



	const dataQuiz = [
		{
			"category": "Geography",
			"type": "multiple",
			"difficulty": "medium",
			"question": "Which state of the United States is the smallest?",
			"correct_answer": "Rhode Island ",
			"incorrect_answers": [
				"Maine",
				"Vermont",
				"Massachusetts"
			],
			"answersOptions": [
				"Maine",
				"Vermont",
				"Massachusetts",
				"Rhode Island "
			]
		},
		{
			"category": "Geography",
			"type": "multiple",
			"difficulty": "hard",
			"question": "The mountainous Khyber Pass connects which of the two following countries?",
			"correct_answer": "Afghanistan and Pakistan",
			"incorrect_answers": [
				"India and Nepal",
				"Pakistan and India",
				"Tajikistan and Kyrgyzstan"
			],
			"answersOptions": [
				"India and Nepal",
				"Pakistan and India",
				"Tajikistan and Kyrgyzstan",
				"Afghanistan and Pakistan"
			]
		},
		{
			"category": "Entertainment: Video Games",
			"type": "multiple",
			"difficulty": "hard",
			"question": "What&#039;s the name of the halloween-related Sims 4 Stuff Pack released September 29th, 2015?",
			"correct_answer": "Spooky Stuff",
			"incorrect_answers": [
				"Ghosts n&#039; Ghouls",
				"Nerving Nights",
				"Fearful Frights"
			],
			"answersOptions": [
				"Ghosts n&#039; Ghouls",
				"Nerving Nights",
				"Fearful Frights",
				"Spooky Stuff"
			]
		},
		{
			"category": "Celebrities",
			"type": "multiple",
			"difficulty": "medium",
			"question": "Before voicing Pearl in Steven Universe, Deedee Magno Hall was part of which American band?",
			"correct_answer": "The Party",
			"incorrect_answers": [
				"The Weather Girls",
				"The Pussycat Dolls",
				"The Cheetah Girls"
			],
			"answersOptions": [
				"The Weather Girls",
				"The Pussycat Dolls",
				"The Cheetah Girls",
				"The Party"
			]
		},
		{
			"category": "Entertainment: Japanese Anime & Manga",
			"type": "multiple",
			"difficulty": "easy",
			"question": "On what medium was &quot;Clannad&quot; first created?",
			"correct_answer": "Visual novel",
			"incorrect_answers": [
				"Anime",
				"Manga",
				"Light novel"
			],
			"answersOptions": [
				"Anime",
				"Manga",
				"Light novel",
				"Visual novel"
			]
		},
		{
			"category": "Entertainment: Japanese Anime & Manga",
			"type": "multiple",
			"difficulty": "medium",
			"question": "In &quot;One Piece&quot;, which one of the following is NOT an Ancient Weapon?",
			"correct_answer": "Jupiter",
			"incorrect_answers": [
				"Uranus",
				"Poseidon",
				"Pluton"
			],
			"answersOptions": [
				"Uranus",
				"Poseidon",
				"Pluton",
				"Jupiter"
			]
		},
		{
			"category": "Vehicles",
			"type": "multiple",
			"difficulty": "medium",
			"question": "Which car manufacturer created the &quot;Aventador&quot;?",
			"correct_answer": "Lamborghini",
			"incorrect_answers": [
				"Ferrari",
				"Pagani",
				"Bugatti"
			],
			"answersOptions": [
				"Ferrari",
				"Pagani",
				"Bugatti",
				"Lamborghini"
			]
		},
		{
			"category": "Entertainment: Video Games",
			"type": "multiple",
			"difficulty": "hard",
			"question": "In the &quot;Ace Attorney&quot; series, who was the truly responsible of the forging of the autopsy report of the pivotal IS-7 incident?",
			"correct_answer": "Bansai Ichiyanagi",
			"incorrect_answers": [
				"Manfred Von Karma",
				"Gregory Edgeworth",
				"Tateyuki Shigaraki"
			],
			"answersOptions": [
				"Manfred Von Karma",
				"Gregory Edgeworth",
				"Tateyuki Shigaraki",
				"Bansai Ichiyanagi"
			]
		},
		{
			"category": "General Knowledge",
			"type": "multiple",
			"difficulty": "hard",
			"question": "What is the most commonly used noun in the English language?",
			"correct_answer": "Time",
			"incorrect_answers": [
				"Home",
				"Water",
				"Man"
			],
			"answersOptions": [
				"Home",
				"Water",
				"Man",
				"Time"
			]
		},
		{
			"category": "Politics",
			"type": "multiple",
			"difficulty": "medium",
			"question": "The Watergate Scandal occured in what year?",
			"correct_answer": "1972",
			"incorrect_answers": [
				"1973",
				"1974",
				"1971"
			],
			"answersOptions": [
				"1973",
				"1974",
				"1971",
				"1972"
			]
		}
	]

	
	const [key, setKey] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [duration, setDuration] = useState(80)
	const [timer, setTimer] = useState(duration)
	const [isPlaying, setIsPlaying] = useState(true)
	const [questions, setQuestions] = useState(dataQuiz)
	const [option, setOption] = useState(12)
	const [amount, setAmount] = useState(10)
	const [url, setUrl] = useState("https://opentdb.com/api.php?amount=10&category=12&&type=multiple&encode=url3986")
	const [game , setGame] = useState(false)
	const start = () =>{
		const play = game ? false:true
	setGame(g=>play)
	}


	useEffect(() => {
		setUrl(u => "https://opentdb.com/api.php?amount=" + amount + "&category=" + option + "&&type=multiple&encode=url3986")
		setDuration(d => amount * 8)
	}, [option, amount, key, duration])

	useEffect(() => {
		async function fetchData() {
			const newQuestions = await getQuestions(url)
			setQuestions(newQuestions)

		}
		fetchData()
	}, [url])

	const handleSelected = (e) => {
		e.preventDefault()
		setGame(false)
		setOption(o => e.target.value)
		setCurrentQuestion(0)
		setScore(0)
		setShowScore(false);
		setTimer(amount * 8)
		setIsPlaying(true)
		setKey(k => k + 1)
	}
	
	const handleAmount = (e) => {
		e.preventDefault()
		setGame(false)
		setAmount(e.target.value)
		setCurrentQuestion(0)
		setScore(0)
		setShowScore(false);
		setDuration(d => e.target.value * 8)
		setTimer(t => e.target.value * 8)
		setKey(k => k + 1)
		setIsPlaying(true)

	}
	useEffect(() => {
		const time = window.setInterval(() => {
			setTimer(t => t - 1)
			if (timer === 0) {
				setShowScore(true)
				clearInterval(time)
			}
		}, 1000)
		return function () {
			clearInterval(time)
		}

	}, [timer])


	const handlePlayAgain = () => {
		setShowScore(false);
		setScore(0)
		setCurrentQuestion(0)
		setIsPlaying(true)
		setAmount(a => amount)
		setDuration(d => amount * 8)
		setTimer(t => amount * 8)
		setKey(k => k + 1)
		
	}

	const handleAnswerButtonClick = (answersOption, current) => {
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setIsPlaying(false)
			setShowScore(true);
		}
		if (answersOption === questions[currentQuestion].correct_answer) {
			setScore(score + 1);
		}
	};
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);


	return (<div className="App">
		<Column
			left={<Select value={option} onChange={handleSelected} elements='ctegories' />}
			right={<Select value={amount} onChange={handleAmount} elements='amounts' />}
		></Column>
{game ? null:<div className="question-section"><button onClick={() => start()}>start</button></div>}
{game ?<div>{showScore ? (
			<div className='score-section'>Your score : {score} out of {questions.length}
				<button onClick={() => handlePlayAgain()}>play again</button>
			</div>
		) : (
			<>
				<div className='question-section'>
					<div className='question-count'>
						<span>Question {currentQuestion + 1}</span>/{questions.length}
					</div>
					<div className='question-text'>{decodeURIComponent(questions[currentQuestion].question)}</div>
				</div>
				<div className='answer-section'>
					{questions[currentQuestion].answersOptions.map((answersOption, index) =>
						<button key={index} onClick={() => handleAnswerButtonClick(answersOption, currentQuestion)}>{answersOption}</button>
					)}

				</div>


			</>
		)}
		<div className="timer">
			<CounterDown isPlaying={isPlaying} duration={duration}  setKey={key} />
		</div></div>:null}
	</div>
	);
}

function Column({ left, right }) {
	return <div className="selectOptions">
		<div className="row">
		<div className="col-md-6">{left}</div>
		<div className="col-md-6">{right}</div>
	</div>
	</div>
}

