import React from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export default function CounterDown({ isPlaying, duration, setKey }) {
    // const [Playing, setIsPlaying] = useState(isPlaying)
    // const [IsComplete, setComplete] = useState(complete)
    // const [time, setTime] = useState(10)
    // const [reset, setReset] = useState(key);
  
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            return <div className="timer value">Too late</div>;
        }
        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    }

    return (<div className="timer-counter-down">
        
             <CountdownCircleTimer
                isPlaying={isPlaying}
                duration={duration}
                strokeWidth={20}
                key={setKey}
                colors={[
                    ['#004777', 0.33],
                    ['#F7B801', 0.33],
                    ['#A30000', 0.33],
                ]}
                onComplete={() => [false, 1000]}
            >
                {renderTime}
            </CountdownCircleTimer>
    </div>)
}