import React, { useEffect, useState } from 'react';
import Test from './Test';


export default function Exemple() {
	const [game , setGame] = useState(false)
const start = () =>{
	const play = game ? false:true
setGame(g=>play)
}
useEffect(()=>{

},[game])
	return (

		<div className="App">
			{game ? null:<div className="question-section"><button onClick={() => start()}>start</button></div>}
			{game ? <Test /> : null}
		</div>
	);
}

