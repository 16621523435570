import React, { useEffect, useState } from 'react';
import CounterDown from './CounterDown';


export default function Test() {
	const questions = [
		{
			questionText: "Qu'est-ce qui est indispensable au bon fonctionnement d'un site web écrit avec PHP afin qu'il s'affiche correctement dans un navigateur ?",
			answerOptions: [
				{ answerText: "Un terminal (ligne de commande)", isCorrect: false },
				{ answerText: "Un IDE", isCorrect: false },
				{ answerText: "Un serveur web (Apache, Nginx…)", isCorrect: true },

			],
		},
		{
			questionText: "PHP permet de créer des sites...",
			answerOptions: [
				{ answerText: "statiques", isCorrect: false },
				{ answerText: "dynamiques", isCorrect: true },

			],
		},
		{
			questionText: "Sélectionnez des technologies concurrentes de PHP",
			answerOptions: [
				{ answerText: "Ruby on Rails", isCorrect: true },
				{ answerText: "MySQL", isCorrect: false },
				{ answerText: "HTML", isCorrect: false },
			],
		},
		{
			questionText: "Sélectionnez des éditeurs de texte dans lesquels on peut écrire du code PHP :",
			answerOptions: [
				{ answerText: "Apache", isCorrect: false },
				{ answerText: "Google Chrome", isCorrect: false },
				{ answerText: "Sublime Text", isCorrect: true },

			],
		},
		{
			questionText: "À quoi ressemble une balise PHP ?",
			answerOptions: [
				{ answerText: "<?/ ?>", isCorrect: false },
				{ answerText: "<php ?>", isCorrect: false },
				{ answerText: "<?php ?>", isCorrect: true },

			],
		},
		{
			questionText: "Pourquoi faut-il configurer PHP pour afficher des erreurs sur sa machine ?",
			answerOptions: [
				{ answerText: "Cela nous permet d'éviter un bug de PHP qui empêche les pages de s'afficher correctement", isCorrect: false },
				{ answerText: "Cela accélère le temps d'affichage d'une page", isCorrect: false },
				{ answerText: "Cela nous permet de comprendre nos erreurs et de les résoudre plus vite", isCorrect: true },

			],
		},
	];

	const [key, setKey] = useState(0);
	
	const [timer, setTimer] = useState(60)
	const [isPlaying, setIsPlaying] = useState(true)
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const handleAnswerButtonClick = (isCorrect) => {
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setIsPlaying(false)
			setShowScore(true);;
		}
		if (isCorrect) {
			setScore(score + 1);
		}
	};
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);

	useEffect(() => {
		const time = window.setInterval(() => {
			setTimer(t => t - 1)
			if (timer === 0) {
				setShowScore(true)
				clearInterval(time)
			}
		}, 1000)
		return function () {
			clearInterval(time)
		}

	}, [timer ])

	const handlePlayAgain = () => {
		setShowScore(false);
		setScore(0)
		setCurrentQuestion(0)
		setIsPlaying(true)
		//setAmount(a => amount)
		//setDuration(d => amount * 4)
		setTimer(t => 60)
		setKey(k => k + 1)
		
	}


	return (

		<div className="App">
			{/* HINT: replace "false" with logic to display the 
      score when the user has answered all the questions */}
			{showScore  ? (
				<div className='score-section'>Your score : {score} out of {questions.length}
					<button onClick={() => handlePlayAgain()}>play again</button>
				</div>
			) : (
				<>
					<div className='question-section'>
						<div className='question-count'>
							<span>Question {currentQuestion + 1}</span>/{questions.length}
						</div>
						<div className='question-text'>{questions[currentQuestion].questionText}</div>
					</div>
					<div className='answer-section'>
						{questions[currentQuestion].answerOptions.map((answerOption, index) =>
							<button key={index} onClick={() => handleAnswerButtonClick(answerOption.isCorrect)}>{answerOption.answerText}</button>
						)}

					</div>
					
				</>
			)}
			<div className="timer-counter-down">
					<CounterDown isPlaying={isPlaying} duration={60}  setKey={key} />
					</div>
		</div>
	);
}

