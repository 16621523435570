

export default async function getQuestions(url) {
    const response = await fetch(url)
        const quizs = await response.json()
        let data = []
        quizs.results.map(result=>{
            let incorrectAnswers = []
				result.correct_answer = decodeURIComponent(result.correct_answer)
				result.incorrect_answers.map(incorrect_answer => {
					incorrect_answer = decodeURIComponent(incorrect_answer)
					incorrectAnswers = [...incorrectAnswers, incorrect_answer]
					return (incorrectAnswers)
				})
            const quiz = { ...result, answersOptions: [...incorrectAnswers, result.correct_answer] }
            data = [...data, quiz]
            return (data)
        })
            return (data)

}
